@import './../sass/reuse';
.labHeaderContainer {
  background: #1c1c1c;
}
.header {
  position: relative;
  height: $header-height;
  z-index: 1;
  align-items: center;

  @include tablet {
    height: $header-height-tablet;
  }

  @include desktop {
    padding: 2em 0;
    height: 100%;
    display: flex;
  }

  @include fullhd {
    padding: 47px 0;
  }

  &:not(.hasSearch) {
    nav {
      margin-right: auto;
    }
  }
}
.labHeader {
  align-items: center;
}

.logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  svg {
    max-width: 100%;
  }

  @include desktop {
    position: static;
    transform: none;
    margin-right: $gap / 2;
  }

  @include fullhd {
    margin-right: $gap;
    margin-left: 0;
  }
}

.hamburger {
  font-size: $size-3;
  height: $header-height;
  line-height: $header-height;
  border: 0;
  background: transparent;
  color: $body-color;
  padding: 0 $gap / 2;

  @include tablet {
    padding: 0 $gap;
    height: $header-height-tablet;
    line-height: $header-height-tablet;
  }

  @include desktop {
    display: none;
  }

  &Right {
    float: right;
    margin-right: 30px;
    @include tablet {
      margin-right: 60px;
    }
  }
}

.menuOpen {
  position: fixed;
  z-index: 1;
  background-color: $body-background-color;
  width: 100%;
  height: calc(100vh - #{$header-height});
  top: $header-height;
  left: 0;
  overflow-y: auto;

  @include tablet {
    top: $header-height-tablet;
    height: calc(100vh - #{$header-height-tablet});
  }

  @include desktop {
    display: flex;
    position: static;
    height: auto;
    flex: 0 0 auto;
  }
}

.menuClosed {
  display: none;

  @include desktop {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    &.storeHeaderMenuContainer {
      justify-content: flex-end;
    }
    &.labHeaderMenuContainer {
      position: relative;
      justify-content: flex-start;
    }
  }
  form {
    &:not(.storeSearchForm):not(.labSearchForm) {
      @include until-widescreen {
        display: none !important;
      }
    }
    &.storeSearchForm {
      @include until(1600) {
        max-width: 175px;
      }

      @include touch {
        display: none !important;
      }
    }
    &.labSearchForm {
      margin-top: 8px;
      width: 200px;

      @include desktop {
        min-width: 200px;
      }
      @include widescreen {
        width: 250px;
      }
      @include fullhd {
        width: 350px;
      }
    }
  }
}

.mobileSearch {
  display: none;
  &:not(.storeMobileSearch) {
    @include until-widescreen {
      display: block;
    }
  }

  &.storeMobileSearch {
    @include touch {
      display: block;
    }
  }
  .searchIcon {
    svg path {
      fill: var(--body-color);
    }
  }
}

.searchContainer {
  form {
    display: block !important;
    width: 100%;
    max-width: 100% !important;
    & > div {
      position: fixed;
      top: 84px;
      height: calc(100vh - 85px);
      overflow: auto;
      left: 0 !important;
      width: 100vw;
      @include tablet {
        top: 144px;
        height: calc(100vh - 14px);
      }
    }
  }
  padding: 20px 15px;
  @include tablet {
    padding: 50px 40px;
  }
  @include until-widescreen {
    justify-content: space-between;
    align-items: center;
    position: fixed;
    display: none;
    width: 100%;
    left: 0;
    top: 0;
    background: $white;
    z-index: 100;
    form {
      width: 100%;
      max-width: 100%;
    }
  }
}

.closeSearch {
  margin-left: 30px;
  position: relative;
  top: 3px;
  cursor: pointer;
  svg {
    color: $black;
    width: 30px !important;
    height: 30px !important;
  }

  @include widescreen {
    display: none;
  }
}

.searchContainerOpen {
  display: flex;
}

.searchIcon {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 29px;
  right: 60px;
  @include tablet {
    top: 48px;
    right: 100px;
  }
  @include desktop {
    position: relative;
    top: 3px;
    right: 0;
    margin-left: 20px;
  }
  @include until-widescreen {
    display: block;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}
.labSearchIcon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-top: 16px;
  svg {
    width: 20px;
    height: 20px;
  }
}

.nav {
  font-size: $size-6;

  @include desktop {
    font-size: rem(14px);
    display: flex;
    margin-right: $gap / 2;
  }

  @include fullhd {
    margin-right: $gap;
  }

  ul {
    text-align: center;
    margin-top: $gap;
    margin-bottom: $gap;

    @include desktop {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    li {
      margin-bottom: $gap / 2;

      @include desktop {
        margin-bottom: 0;
      }
    }
    .chat {
      @include desktop {
        color: #96ffef;
        border-left: 1px solid #96ffef;
        padding: $gap / 4 $gap;
        margin-left: $gap;

        &::after {
          content: none;
        }
      }
    }
  }

  a,
  button {
    color: var(--nav-link-color);
    padding: $gap / 2;
    font-weight: $weight-bold;
    letter-spacing: 3.4px;
    display: block;
    text-transform: uppercase;
    position: relative;

    &:after {
      will-change: width;
      transition: width $speed * 2;
      content: '.';
      position: absolute;
      visibility: hidden;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      color: transparent;
      background-color: $link-hover;
      height: 1px;
    }

    &:hover {
      &:after {
        visibility: visible;
        width: 100%;
      }
    }

    &.active {
      color: #f79ddb;
    }
  }
}

.signoutOptions {
  padding: 1em;

  @include desktop {
    padding: 0;
  }

  a,
  button {
    @include touch {
      display: block;
      width: 250px;
      text-align: center;
      margin: 0 auto;
      padding: 12px !important;
      font-size: 12px;
    }
    @include desktop {
      margin-left: 8px;
      margin-bottom: 0;
    }
  }

  a {
    @include touch {
      margin-bottom: 20px;
    }
  }
}

.avatarContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  column-gap: 16px;
}

.storeHeaderMenu {
  display: flex;
  margin-right: $gap / 2;

  @include touch {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    li {
      margin-bottom: $gap / 2;
      padding: $gap / 2;
    }
  }

  a {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
    display: inline-block;
    margin-left: $gap / 2;
    * {
      vertical-align: middle;
    }
    font-size: $size-6;

    @include tablet {
      font-size: 2rem;
    }
    @include desktop {
      font-size: 14px;
    }
    @include touch {
      margin-left: auto;
      margin-right: auto;
    }
    @include touch {
      flex-direction: column;
      align-items: center;
    }
  }
}

.storeHeaderMenuLinkText {
  margin-left: 9px;
}

.cartIcon {
  position: relative;
  margin-right: 14px;
  &Empty {
    margin-right: 0;
    .cartCircle {
      display: none;
    }
  }
}
.cartCircle {
  border: 2px solid #dac7fa;
  border-radius: 50%;
  background-color: $white;
  color: $purple;
  font-size: 12px;
  font-weight: 700;
  height: 20px;
  position: absolute;
  top: -14px;
  right: -14px;
  text-align: center;
  width: 20px;
  letter-spacing: normal;
}
