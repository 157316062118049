@import './../../sass/reuse';

.switcher {
  display: flex;
  position: relative;
  div {
    align-items: center;
    cursor: pointer;
  }

  input {
    display: none;
    &:checked {
      & + span {
        &:after {
          right: 5px;
          background: $purple;
        }
        &:before {
          background-color: $white;
        }
      }
    }
  }

  span {
    font-weight: $weight-bold;
    font-size: 14px;
    letter-spacing: 2.8px;
    text-transform: uppercase;
    display: inline-block;

    @include mobile {
      font-size: 11px;
      letter-spacing: 1.6px;
    }
  }
}

.switcherContainer {
  position: relative;
}

.label {
  margin-right: 66px;

  @include mobile {
    margin-right: 40px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    transition:
      background-color $duration $function,
      right $duration $function;
  }

  &:before {
    background: #616161;
    width: 46px;
    height: 28px;
    top: 2px;
    right: 0;
    border: 1px solid var(--switcher-border-color);
    border-radius: 14px;

    @include mobile {
      top: 5px;
      width: 28px;
      height: 18px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    background: #fff;
    width: 20px;
    height: 20px;
    top: 6px;
    right: 21px;
    border-radius: 50%;

    @include mobile {
      top: 8px;
      right: 13px;
      width: 12px;
      height: 12px;
    }
  }
}
