@import '../sass/reuse';

.notification {
  &Bell {
    cursor: pointer;
    position: absolute;
    right: 0;
    display: flex;
    padding: 10px;
    top: 4px;
    transform: scale(0.9);
    top: 10px;

    @include tablet {
      top: 31px;
      right: 30px;
      padding: 8px;
    }

    @include desktop {
      position: relative;
      right: 0;
      top: 4px;
      margin-left: $gap;
    }

    @include fullhd {
      margin-right: 3px;
    }

    svg {
      fill: #313131;
    }

    &White {
      svg {
        fill: $white;
      }
    }

    &Purple {
      svg {
        fill: $purple;
      }
    }
  }

  &BellCircle {
    border: 1px solid var(--circle-border-color);
    border-radius: 10px;
    background-color: $white;
    color: $purple;
    display: none;
    font-size: 14px;
    font-weight: 700;
    height: 20px;
    margin-left: -10px;
    margin-top: -2px;
    min-width: 20px;
    padding-inline: 2px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &Circle {
    background-color: $purple;
    color: white;
    font-size: 9px;
    font-weight: 700;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 6px;
  }

  &Type {
    flex: 1;
  }

  &Items {
    max-height: 462px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &Dropdown {
    border-radius: $radius;
    background-color: var(--announcements-background-color);
    box-shadow: 0 93px 99px var(--header-dropdown-shadow-color);
    padding: 15px 20px 20px;
    position: absolute;
    right: 0;
    top: 81px;
    width: 463px;
    z-index: 10;

    @include mobile {
      padding: 10px;
    }

    @include tablet {
      right: 30px;
      top: 90px;
    }

    @include desktop {
      top: 80px;
    }

    &::before {
      content: '';
      border-style: solid;
      border-width: 20px 11px 0px 11px;
      border-color: var(--announcements-background-color) transparent;
      height: 0;
      position: absolute;
      right: 13px;
      top: -17px;
      transform: rotate(180deg);
      width: 0;

      @media (max-width: 520px) {
        right: 15px;
      }

      @include desktop {
        right: 140px;
      }
    }

    @media (max-width: 520px) {
      right: 0;
      width: 100%;
    }

    @include desktop {
      right: 0;
    }

    &Header {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
      padding: 0 15px 10px 20px;
      text-transform: uppercase;

      @include mobile {
        padding: 0 10px;
      }

      p {
        font-size: 12px;
        font-weight: 700;
        padding: 5px 0;
      }
    }

    &Filters {
      color: $cyan;
      font-size: 9px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: 1.35px;
      margin-bottom: 5px;

      span {
        padding: 5px 0;
        cursor: pointer;

        &:first-child {
          margin-right: 8px;
        }

        &:last-child {
          margin-left: 8px;
        }
      }
    }
  }
}

.notice {
  align-items: center;
  border-radius: $radius;
  background-color: var(--notice-active-background-color);
  display: flex;
  height: 56px;
  margin: 10px 0;
  padding-right: 10px;

  &Inactive {
    background-color: var(--notice-inactive-background-color);
    .noticeText {
      color: var(--notice-inactive-color);
    }
  }

  &NoNotices {
    justify-content: center;

    p {
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &Indicator {
    background-color: $purple;
    border-radius: $radius;
    height: 48px;
    margin-left: 5px;
    margin-right: 15px;
    width: 9px;

    &:hover {
      cursor: pointer;
    }

    &Inactive {
      background-color: #585858;
    }
  }

  &Text {
    color: var(--notice-active-color) !important;
    flex-grow: 1;

    &Read {
      display: flex;
      justify-content: space-between;
      margin-right: 5px;
    }

    &Notify {
      display: flex;
      align-items: center;

      img {
        border-radius: 50%;
      }
      span {
        margin-left: 18px;
      }
    }

    a {
      color: var(--notice-active-color) !important;
    }
  }

  &Title {
    font-size: 12px;
    font-weight: 500;

    div {
      font-weight: 400;
    }

    @include mobile {
      font-size: 10px;
    }
  }

  &Subtitle {
    font-size: 12px;
    font-weight: 400;
  }

  &Buttons {
    display: flex;
    margin-left: 10px;
    width: 140px;
  }

  &Button {
    border-radius: $radius;
    color: #ffffff;
    height: 38px;
    position: relative;
    width: 65px;

    span {
      font-size: 12px;
      font-weight: 700;
      left: 50%;
      position: absolute;
      text-transform: uppercase;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &Accept {
      background-color: #2eeab6;
      margin-right: 5px;
      &Animate {
        button {
          animation: expand-width 1s;
          width: 140px;
          margin-right: 0;
        }

        .noticeButtonDecline {
          display: none;
        }
      }
    }

    &Accepted,
    &Declined {
      button {
        width: 140px;
        margin-right: 0;
      }
    }

    &Accepted {
      .noticeButtonDecline {
        display: none;
      }
    }

    &Declined {
      .noticeButtonAccept {
        display: none;
      }
    }

    &Decline {
      background-color: #e9314e;
      margin-left: 5px;

      &Animate {
        flex-direction: row-reverse;
        button {
          animation: expand-width 1s;
          width: 140px;
          margin-left: 0;
        }

        .noticeButtonAccept {
          display: none;
        }
      }
    }
  }

  &Icon {
    transform: scale(0.86);
  }
}

@keyframes expand-width {
  from {
    width: 65px;
  }

  to {
    width: 140px;
  }
}

.accepted {
  color: #28eab6;
  font-size: 2rem;
}

.declined {
  color: #e9314e;
  font-size: 2rem;
}

.displayFlex {
  display: flex;
}

.informationButton {
  width: 140px;
  margin: 0;
}

.announcementButtons {
  display: flex;
  column-gap: 8px;
  padding-block: 14px;

  button {
    cursor: pointer;
    flex: 1;
    border-radius: 6px;
    background: #ffffff14;
    color: var(--text-color);
    font-weight: 700;
    font-size: 8px;
    padding: 11px 10px;
    height: 32px;
    position: relative;

    &.isActive {
      background: #7479dc;
      color: white;
    }
  }
}
