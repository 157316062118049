@import './../sass/reuse';

.avatar {
  width: fit-content;
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: #0b0b0b;
  border-radius: 28px;
  padding: 8px;
  padding-right: 12px;
  cursor: pointer;

  img {
    width: 46px;
    height: 46px;
    border-radius: 46px;
    object-fit: cover;
  }
  &.isOpen {
    @include desktop {
      border-radius: 28px 28px 0 0;
    }
  }
}

.initials {
  font-weight: 700;
  font-size: 11px;
  margin-right: 4px;
  margin-left: 16px;
  color: #fff;
}

.avatarButtons {
  display: flex;
  column-gap: 8px;
  background-color: #0b0b0b;
  padding: 14px 16px;

  a {
    cursor: pointer;
    flex: 1;
    border-radius: 6px;
    background: #ffffff14;
    color: #fff;
    font-weight: 700;
    font-size: 8px;
    padding: 11px 20px;
    text-align: center;

    &.isActive {
      background: #7479dc;
    }
  }
}

.avatarDropdown {
  background-color: #fff;
  border-radius: 28px 0 28px 28px;
  overflow: hidden;
  position: absolute;
  right: 0;
}

.avatarContainer {
  position: relative;
}

$dropdown-radius: 28px;
.dropdown {
  box-shadow: 0 93px 99px var(--header-dropdown-shadow-color);
  border-top-right-radius: $dropdown-radius;
  border-bottom-right-radius: $dropdown-radius;
  background-color: var(--header-dropdown-menu-background-color);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1000;

  @include mobile {
    background: #424242;
  }

  @include desktop {
    border-radius: 28px;
    border-top-right-radius: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 100%;
    left: auto;
    width: 388px;
    height: auto;
  }
}

.dropdownContainer {
  padding: $gap;

  ul {
    font-size: 1.6rem;
    font-weight: $weight-bold;
    letter-spacing: 3.4px;
    text-transform: uppercase;
    @include desktop {
      font-size: 17px;
    }
  }

  a {
    color: $body-background-color;
    padding: 10px 0;
    display: flex;
    align-items: center;

    span {
      margin-left: 32px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &:hover {
      color: $purple;
      svg {
        color: $purple;
        path,
        circle,
        rect {
          fill: $purple;
        }
        rect {
          stroke: $purple;
        }
      }
    }

    @include mobile {
      color: $white;
      svg {
        path,
        circle,
        rect {
          fill: $white;
        }
        rect {
          stroke: $white;
        }
      }
    }

    svg {
      width: 19px;
    }
  }
}

.dropdownCloseButton {
  background: transparent;
  right: 0;
  top: 0;
  width: 64px;
  height: 64px;
  position: absolute;
  font-size: 2rem;
  color: $white;

  @include desktop {
    display: none;
  }
}

.displayNone {
  display: none;
}

.darkTheme,
.lightTheme {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  height: 73px;
  padding-inline: $gap;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.14em;
  text-transform: uppercase;

  span {
    @include mobile {
      font-size: 14px !important;
    }
  }
}

.darkTheme {
  color: #fff;
  background-color: #343434;
}
.lightTheme {
  color: #9d9d9d;
  background-color: #d8d8d8;
}

.switcherDark {
  top: -5px;
  input {
    & + span {
      &:after {
        background: #fff;
      }
      &:before {
        background-color: #8753db;
      }
    }
  }
}

.switcherLight {
  top: -5px;
  input {
    & + span {
      &:after {
        right: 5px;
        background: #fff;
      }
      &:before {
        background-color: #b9b9b9;
      }
    }
  }
}

.switcherLabel {
  flex: 1;
  margin-left: 24px;
}
